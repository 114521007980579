import { LangCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import { withHtml } from '@components/logic';
import { Breadcrumbs } from '@components/shared';
import type { IPage, IPost, IProduct, ILocation } from '@standardTypes';
import React from 'react';

interface ContactProps {
    pageContext: {
        page: IPage;
        translationUrls: Object;
    };
    location: ILocation;
}

const Content = withHtml('article');

const ContactPage: React.FC<ContactProps> = ({ pageContext, location }) => {
    const { page, translationUrls } = pageContext;

    return (
        <Layout {...page} translationUrls={translationUrls}>
            <h1>{page.title}</h1>
            <Content>{page.content}</Content>
        </Layout>
    );
};

export default ContactPage;
