import React, { useRef, useEffect, useState } from 'react';

// React.forwardRef((props, ref) => (
//     <button ref={ref} className="FancyButton">
//       {props.children}
//     </button>
//   ));

const withHTML = <T,>(HtmlElementAsString = 'div') =>
    React.forwardRef<React.Ref<T>, any>(({ children, ...props }, ref) => (
        <HtmlElementAsString
            dangerouslySetInnerHTML={{ __html: children }}
            {...props}
            ref={ref}
        />
    ));

const HTMLAsChildren = (__html: string) => {
    const [text, setter] = useState('');
    const component = useRef<HTMLElement | null>(null);
    const Elem = () => (
        <span ref={component} dangerouslySetInnerHTML={{ __html }} />
    );

    useEffect(() => {
        if (component.current) {
            setter(component.current.innerText);
        }
    }, [component]);

    return [component, text];
};

export { HTMLAsChildren };

export default withHTML;
